import React, { useContext } from 'react'
import { MdLocationPin } from 'react-icons/md';
import { motion } from 'framer-motion';
import { BsInstagram, BsFacebook, BsTelegram } from 'react-icons/bs';
import { images } from '../../constants';
import AppContext from '../../context/AppContext'

import './Contacts.scss';

const Contacts = () => {
  const { toggleVisibility } = useContext(AppContext);

  return (
    <motion.div
      animate={{ x:[0,0], y: [100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.7 }}
      className="app__contacts">
        <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__contacts-item"
          >
            <button onClick={toggleVisibility}>Залишити заявку</button>
          </motion.div>
          <div className="app__contacts-item">
            <a className="app__contacts-phone-number" href="tel:+380 (93) 180 1177">+380 (93) 180 1177</a>
            <a className="app__contacts-phone-number" href="tel:+380 (98) 180 1177" style={{ marginTop: '10px' }}>+380 (98) 180 1177</a>
          </div>

          <div className="app__contacts-item app__contacts-address">
          <div className="app__footer-address-block">
            <MdLocationPin size={32} color="#FFFFFF" />
            <a href='https://goo.gl/maps/nKdCkwEYPmba2j6H8' target="_blank" rel="noopener noreferrer" className="app__contacts-address">вул. Г.Мазепи 1Б (ТРЦ спартак)</a>
          </div>
          <div className="app__footer-address-block">
            <MdLocationPin size={32} color="#FFFFFF" />
            <a href='https://goo.gl/maps/SA29J62TX2sfeyoB7' target="_blank" rel="noopener noreferrer" className="app__contacts-address" style={{ marginTop: '10px' }}>вул. Академіка Колесси 2 (центр)</a>
          </div>
          <div className="app__footer-address-block">
            <MdLocationPin size={32} color="#FFFFFF" />
            <a href='https://goo.gl/maps/TVQdCMEW9AED5PH38' target="_blank" rel="noopener noreferrer" className="app__contacts-address" style={{ marginTop: '10px' }}>вул. Наукова 7А (бц "ОПТІМА ПЛАЗА")</a>
          </div>
          <div className="app__footer-address-block">
            <MdLocationPin size={32} color="#FFFFFF" />
            <a href='https://maps.app.goo.gl/RUJ5dQW7UVuGRJmu6?g_st=it' target="_blank" rel="noopener noreferrer" className="app__contacts-address" style={{ marginTop: '10px' }}>пл. Князя Святослава 5. (жк. "Семицвіт")</a>
          </div>
          <div className="app__footer-address-block">
            <MdLocationPin size={32} color="#FFFFFF" />
            <a href='https://maps.app.goo.gl/5xyfiZrxwexbXw4v5' target="_blank" rel="noopener noreferrer" className="app__contacts-address" style={{ marginTop: '10px' }}>Вул.Водогінна 2 (Львівводоканал)</a>
          </div>    
          </div>

          <div className="app__contacts-item app__contacts-sn">
            <a href="https://instagram.com/autoinstruktor.lviv?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer" >
              <BsInstagram size={24} color="white" className='app__contacts-sn-item' />
            </a>
            <a href="https://www.facebook.com/people/autoinstructorlviv/100047391673967/" target="_blank" rel="noreferrer">
              <BsFacebook size={24} color="white" className='app__contacts-sn-item' />
            </a>
            <a href="https://telegram.me/TAURUS_LVIV" target="_blank" rel="noreferrer">
              <BsTelegram size={24} color="white" className='app__contacts-sn-item' />
            </a>
            <a href="viber://chat?number=0938001062" target="_blank" rel="noreferrer">
              <img src={images.viber} className="app__contacts-sn-item app__svg-viber" href="viber://chat?number=0938001062" loading="lazy" alt="viber" />
            </a>
          </div>
    </motion.div>
  )
}

export default Contacts