import React, { useContext, useState } from "react";
import { useFormik } from 'formik';
import { HiX } from 'react-icons/hi';
import AppContext from '../../context/AppContext';
import { client } from '../../client';
import { useNavigate  } from 'react-router-dom';

import "./ContactForm.scss";

const ContactForm = () => {
    const { isVisible, toggleVisibility } = useContext(AppContext);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const LoadingIcon = () => (
        <svg className="loading-icon" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
            <path fill="#ffffff" d="M222.7 32.1c5 16.9-4.6 34.8-21.5 39.8C121.8 95.6 64 169.1 64 256c0 106 86 192 192 192s192-86 192-192c0-86.9-57.8-160.4-137.1-184.1c-16.9-5-26.6-22.9-21.5-39.8s22.9-26.6 39.8-21.5C434.9 42.1 512 140 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 140 77.1 42.1 182.9 10.6c16.9-5 34.8 4.6 39.8 21.5z"/>
        </svg>
    );

    const locations = [
        {
            id:1,
            name:"вул. Академіка Колесси, 2"
        },
        {
            id:2,
            name:"вул. Г.Мазепи 1Б (ТРЦ спартак )"
        },
        {
            id:3,
            name:'вул. Наукова 7А (БЦ "ОПТІМА ПЛАЗА")'
        },
        {
            id:4,
            name:'пл. Князя Святослава 5. (жк. "Семицвіт")'
        },
        {
            id:5,
            name:'Вул.Водогінна 2 (Львівводоканал)'
        },
    ]

    const phoneRegex = /^\+?3?8?(0\d{9})$/;
    
    const formik = useFormik({
        initialValues: {
          name: '',
          comment: '',
          phoneNumber: '',
          option: ''
        },
        onSubmit: async values => {

            setIsSubmitting(true);
            setIsFormSubmitted(true);

            const clientObj = {
                _type: 'client',
                name: values.name,
                phoneNumber: values.phoneNumber,
                comment: values.comment,
                option: values.option
            };
        
            await fetch('https://www.taurus.school/lead-lviv.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(clientObj),
            })
            .then(() => {
                setIsFormSubmitted(true);
                setIsSubmitting(false);
                //setIsFormSubmitted(true);
                toggleVisibility(false);
                navigate("/thanks");
              })
            .catch((err) => {
                console.log(err);
                setIsSubmitting(false);
            });
        },
        validate: values => {
          const errors = {};

          if (!values.name) {
            errors.name = "Будь ласка, введіть своє ім'я";
          }

          if (!values.phoneNumber) {
            errors.phoneNumber = 'Будь ласка, введіть номер телефону';
          } else if (!phoneRegex.test(values.phoneNumber)) {
            errors.phoneNumber = 'Будь ласка, введіть коректний український номер телефону';
          }

          return errors;
        },
    });

    if (!isVisible) {
        return null;
    }
    
    if (isFormSubmitted) {
        return (
            <div className="overlay" onClick={toggleVisibility}>
            <div className="app_contactform">
                <HiX className="app_contactform-closeicon" onClick={toggleVisibility}/>
                <h1>Дякую!</h1>
                <h2>ми отримали вашу заявку</h2>
                <h3>з вами сконтактує менджер для уточнення додаткової інформації</h3>
            </div>
        </div>
        );
    }

    return (
        <div className="overlay">
            <div className="app_contactform">
                <HiX className="app_contactform-closeicon" onClick={toggleVisibility}/>
                <h1>залиш заявку</h1>
                <h3>(з вами сконтактує менджер для уточнення додаткової інформації)</h3>
                <form className="app_contactform-form" onSubmit={formik.handleSubmit}>
                    {formik.errors.name && formik.touched.name && (
                      <div className="error">{formik.errors.name}</div>
                    )}
                    <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="ім'я"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                    />   

                    {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                      <div className="error">{formik.errors.phoneNumber}</div>
                    )}
                    <input
                        id="phoneNumber"
                        name="phoneNumber"
                        type="tel"
                        placeholder="контактний номер"
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber}
                        required
                    />

                    <input
                        id="comment"
                        name="comment"
                        type="text"
                        placeholder="коментар"
                        onChange={formik.handleChange}
                        value={formik.values.comment}
                    />
                    <label className="radio-group-header">Вкажіть локацію</label>
                    <div className="radio-group">
                        {locations.map((location) => (
                            <label htmlFor={location.id} key={location.id}>
                                <input
                                    id={location.id}
                                    name="option"
                                    type="radio"
                                    value={location.name}
                                    onChange={formik.handleChange}
                                    checked={formik.values.option === location.name}
                                />
                                {location.name}
                            </label>
                        ))}
                    </div>
                    <button type="submit" disabled={isSubmitting} className="submit-button">
                        {isSubmitting ? (
                            <>
                                <LoadingIcon />
                                {' Відправка...'}
                            </>
                        ) : (
                            'Надіслати'
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;